import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Help20 from "@carbon/icons-react/lib/help/20";
import Logout20 from "@carbon/icons-react/lib/logout/20";
import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
} from "carbon-components-react/lib/components/UIShell";
import { setAuthToken } from "../../redux/auth/auth.actions";
import { establishWebsocketConnection } from "../../redux/thunks/thunks.actions";

const HeaderComponent = ({ isLogin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setAuthToken(null));
  };

  const handleLink = (e, url) => {
    e.preventDefault();
    navigate(url);
  };

  useEffect(() => {
    dispatch(establishWebsocketConnection());
  }, [dispatch]);

  return (
    <div id="header-component" data-testid="header-component">
      <Header aria-label="Demarq Search & Locate">
        <HeaderName
          prefix="Search &"
          onClick={(e) => handleLink(e, "/")}
          data-testid="header-name"
        >
          Locate
        </HeaderName>
        {isLogin ? null : (
          <HeaderNavigation aria-label="Demarq Search & Locate Navigation">
            <HeaderMenuItem
              data-testid="documents-nav-option"
              onClick={(e) => handleLink(e, "/")}
            >
              Documents
            </HeaderMenuItem>
            <HeaderMenuItem
              data-testid="results-nav-option"
              onClick={(e) => handleLink(e, "/results")}
            >
              Results
            </HeaderMenuItem>
            <HeaderMenuItem
              data-testid="results-nav-option"
              onClick={(e) => handleLink(e, "/search")}
            >
              Search
            </HeaderMenuItem>
          </HeaderNavigation>
        )}
        <HeaderGlobalBar>
          {isLogin ? null : (
            <HeaderGlobalAction
              aria-label="Logout"
              onClick={handleLogout}
              data-testid="header-logout-button"
            >
              <Logout20 />
            </HeaderGlobalAction>
          )}
        </HeaderGlobalBar>
      </Header>
    </div>
  );
};

HeaderComponent.propTypes = {
  isLogin: PropTypes.bool,
};

export default HeaderComponent;
