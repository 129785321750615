import React, { useState } from "react";
import { Row, Column } from "carbon-components-react";
import HeaderComponent from "../../components/header/header.component";
import DocumentList from "../../components/document-list/document-list.component";
import AddFileButton from "../../components/add-file-button/add-file-button.component";
import AddFileModal from "../../components/add-file-modal/add-file-modal.component";

require("dotenv").config();

const DocumentsPage = () => {
	const [isFileModalOpen, setIsFileModalOpen] = useState(false);

	const toggleFileModal = () => {
		setIsFileModalOpen(!isFileModalOpen);
	};

	const onSubmitFiles = () => {
		setIsFileModalOpen(false);
	};

	return (
		<>
			<div className="bx--grid--full-width page-layout">
				<HeaderComponent isLogin={false} />
				<Row className="row" style={{ overflow: "hidden" }}>
					<Column sm={2} md={4} lg={12} className="dashboard-box">
						<DocumentList />
						<AddFileButton onToggleFileModal={toggleFileModal} />
						<AddFileModal
							showFileModal={isFileModalOpen}
							onSubmitFiles={onSubmitFiles}
							onToggleFileModal={toggleFileModal}
						/>
					</Column>
				</Row>
			</div>
		</>
	);
};

export default DocumentsPage;
