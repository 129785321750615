const pre = "websocket/";
const UPDATE_WEBSOCKET = pre + "UPDATE_WEBSOCKET";
const UPDATE_STATE_WEBSOCKET = pre + "UPDATE_STATE_WEBSOCKET";

const exportedObject = {
    UPDATE_WEBSOCKET,
    UPDATE_STATE_WEBSOCKET,
};

export default exportedObject;
