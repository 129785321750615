import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage";
import apiReducer from "./api/api.reducer";
import authReducer from "./auth/auth.reducer";
import documentsReducer from "./documents/documents.reducer";
import elementsReducer from "./elements/elements.reducer";
import searchResultsReducer from "./search/search.reducer";
import webSocketUpdateReducer from "./websocket/websocket.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  websocket: webSocketUpdateReducer,
  api: apiReducer,
  documents: documentsReducer,
  elements: elementsReducer,
  searchResults: searchResultsReducer,
});

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  whitelist: ["auth", "api", "documents", "elements"],
};

export default persistReducer(persistConfig, rootReducer);
