import types from "./elements.types";

export const initialState = {
	elements: [],
};

export const elementsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_ELEMENTS:
			return { ...state, elements: action.payload };
		default:
			return state;
	}
};

export default elementsReducer;
