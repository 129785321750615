import { Search } from "carbon-components-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { callSearch } from "../../redux/thunks/thunks.actions";

const SearchBar = () => {
  const dispatch = useDispatch();

  const [enteredSearchTerm, setEnteredSearchTerm] = useState();

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      dispatch(callSearch(enteredSearchTerm));
    }
  };

  return (
    <div style={{ marginTop: "32px" }} data-testid="search-bar">
      <Search
        data-testid="search-bar-text-field"
        labelText="Enter search term"
        placeHolderText="Enter search term"
        onChange={(event) => setEnteredSearchTerm(event.target.value)}
        onKeyUp={(event) => {
          searchHandler(event);
        }}
      ></Search>
    </div>
  );
};

export default SearchBar;
