const pre = "auth/";
const SET_AUTH_TOKEN = pre + "SET_AUTH_TOKEN";
const SET_TOKEN_TYPE = pre + "SET_TOKEN_TYPE";
const SET_LOGIN_ERROR = pre + "SET_LOGIN_ERROR";
const CLEAR_LOGIN_ERROR = pre + "CLEAR_LOGIN_ERROR";

const auth = {
	SET_AUTH_TOKEN,
	SET_TOKEN_TYPE,
	SET_LOGIN_ERROR,
	CLEAR_LOGIN_ERROR,
};

export default auth;
