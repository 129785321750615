import types from "./search.types";

export const initialState = {
  searchResults: [],
};

export const searchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case types.CLEAR_SEARCH_RESULTS:
      return { ...state, searchResults: [] };
    default:
      return state;
  }
};

export default searchResultsReducer;
