import { createSelector } from "reselect";

const searchResults = (state) => state.searchResults.searchResults;

export const searchResultsInfo = createSelector(
  [searchResults],
  (searchResult) => {
    return searchResult.map((item, i) => ({
      searchResultId: i,
      id: String(i),
      fileId: item["file_id"],
      fileName: item["file_name"],
      searchHeadline: item["search_headline"],
    }));
  }
);
