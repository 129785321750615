import types from "./websocket.types";

export const initialState = {
    webSocketData: [],
};

export const webSocketUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_WEBSOCKET:
            return {
                ...state,
                webSocketData: [...state.webSocketData, action.payload],
            };
        case types.UPDATE_STATE_WEBSOCKET:
            return {
                ...state,
                webSocketData: state.webSocketData.map((fileIndex) =>
                    action.payload.includes(fileIndex.payload.fileId)
                        ? {
                              ...fileIndex,
                              payload: { ...fileIndex.payload, message: "Seen" },
                          }
                        : fileIndex
                ),
            };

        default:
            return state;
    }
};

export default webSocketUpdateReducer;
