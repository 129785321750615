import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import PrivateRoute from "./helpers/__auth__/private-route.component";
import DocumentsPage from "./pages/documents/documents.page";
import LoginPage from "./pages/login/login.page.jsx";
import ResultsPage from "./pages/results/results.page";
import SearchPage from "./pages/search/search.page";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute redirectTo="/">
            <DocumentsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/results"
        element={
          <PrivateRoute redirectTo="/results">
            <ResultsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/search"
        element={
          <PrivateRoute redirectTo="/search">
            <SearchPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
