import React from "react";
import {
	TableRow,
	TableBody,
	TableCell,
	TableSelectRow,
	Loading,
} from "carbon-components-react";
import { Button } from "carbon-components-react";

const TableDataTableBody = ({ getRowProps, getSelectionProps, rows, pageLocation }) => {
	return (
		<>
			<TableBody data-testid="data-table-body">
				{rows.map((row, i) => {
					return (
						<TableRow key={i} {...getRowProps({ row })}>
							{pageLocation === "Documents" ? (
								<TableSelectRow
									data-testid={`status-${i}`}
									{...getSelectionProps({ row })}
								/>
							) : null}

							{row.cells.map((cell) => {
								if (cell.id.includes("icon")) {
									let status = cell.value.status;
									if (status === "uploading") {
										return (
											<TableCell key={cell.id}>
												<Loading
													description="Processing File"
													id={`${status}-status-${cell.id}`}
													data-testid={`${status}-status-${cell.id}`}
												/>
											</TableCell>
										);
									} else {
										return (
											<TableCell key={cell.id}>
												<Button
													renderIcon={cell.value.icon}
													id={`${status}-status-${cell.id}`}
													data-testid={`${status}-status-${cell.id}`}
													className={`${status}-icon status-icon`}
													iconDescription={cell.value.text}
													hasIconOnly
												/>
											</TableCell>
										);
									}
								} else {
									return (
										<TableCell
											key={cell.id}
											data-testid={`cell-${cell.id}`}
										>
											{cell.value}
										</TableCell>
									);
								}
							})}
						</TableRow>
					);
				})}
			</TableBody>
		</>
	);
};

export default TableDataTableBody;
