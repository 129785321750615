import React from "react";
import { Row, Column } from "carbon-components-react";
import HeaderComponent from "../../components/header/header.component";
import ElementList from "../../components/element-list/element-list.component";

require("dotenv").config();

const ResultsPage = () => {
	return (
		<>
			<div className="bx--grid--full-width page-layout">
				<HeaderComponent isLogin={false} />
				<Row className="row" style={{ overflow: "hidden" }}>
					<Column sm={2} md={4} lg={12} className="dashboard-box">
						<ElementList />
					</Column>
				</Row>
			</div>
		</>
	);
};

export default ResultsPage;
