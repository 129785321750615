import types from "./documents.types";

export const setDocuments = (documents) => {
	return { type: types.SET_DOCUMENTS, payload: documents };
};
export const addDocument = (document) => {
	return { type: types.ADD_DOCUMENT, payload: document };
};

export const removeDocument = (fileId) => {
	return { type: types.REMOVE_DOCUMENT, payload: fileId };
};

export const updateDocument = (websocketMessage) => {
	return { type: types.UPDATE_DOCUMENT, payload: websocketMessage };
};

export const downloadDocument = (fileIds) => {
	return { type: types.DOWNLOAD_DOCUMENT, payload: fileIds };
};
export const setDocumentsAlreadyUploaded = (documents) => {
	return { type: types.SET_ALREADY_UPLOADED, payload: documents };
};

export const clearDocumentsAlreadyUploaded = () => {
	return { type: types.CLEAR_ALREADY_UPLOADED };
};
