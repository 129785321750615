import { setAuthToken, setLoginError } from "../redux/auth/auth.actions";

export const getHeaders = (state) => {
  const headers = {
    Authorization: `${state.auth.tokenType} ${state.auth.authToken}`,
  };

  return headers;
};

export const getZipFile = (data, zipFileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.className = zipFileName;
  // filename based on the excelType
  a.download = zipFileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const handleApiError = (error, dispatch, isLoginEndpoint) => {
  let errorMessage;
  if (error.response) {
    let errorStatus = error.response.status;
    if (errorStatus === 401) {
      if (isLoginEndpoint) {
        errorMessage = "*Incorrect username or password";
        dispatch(setLoginError(errorMessage));
      } else {
        errorMessage = "Your session has timeout. Please login again";
        alert(errorMessage);
        setLoginError(errorMessage);
        if (window.confirm) {
          dispatch(setAuthToken(null));
        }
      }
    }
  } else {
    errorMessage = error.toString();
    alert(errorMessage);
  }
};
