import * as actions from "./middleware.actions";
import { updateWebSocket } from "../websocket/websocket.actions";
import { updateDocument } from "../documents/documents.actions";

const socketMiddleware = () => {
	let socket = null;
	const onOpen = (store) => (event) => {
		store.dispatch(actions.wsConnected(event.target.url));
	};

	const onClose = (store) => () => {
		store.dispatch(actions.wsDisconnected());
	};
	const onMessage = (store) => (event) => {
		const status = event.data;
		const [message, fileId, websocketOCRConfidence] = status.split("->");

		const payload = {
			message: message,
			fileId: parseInt(fileId),
			websocketOCRConfidence: parseInt(websocketOCRConfidence),
		};

		store.dispatch(updateWebSocket({ payload: payload }));
		store.dispatch(updateDocument(payload));
    };
    
	// the middleware part of this function
	return (store) => (next) => (action) => {
		switch (action.type) {
			case "WS_CONNECT":
				if (socket !== null) {
					// socket.close();
				}
				// connect to the remote host
				socket = new WebSocket(action.host);

				// websocket handlers
				socket.onmessage = onMessage(store);
				socket.onclose = onClose(store);
				socket.onopen = onOpen(store);
				break;

			case "WS_DISCONNECT":
				if (socket !== null) {
					socket.close();
				}
				socket = null;
				break;

			default:
				return next(action);
		}
	};
};

export default socketMiddleware();
