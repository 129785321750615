export const tableTitle = "Results";
export const tableDescription = "Results Table Description";

export const headerData = [
	{
		header: "Document Name",
		key: "fileName",
		isSortable: true,
	},
	{
		header: "Page",
		key: "page",
		isSortable: true,
	},
	{
		header: "OCR Confidence",
		key: "ocrConfidence",
		isSortable: true,
	},
	{
		header: "Data Element Type",
		key: "dataElementType",
		isSortable: true,
	},
	{
		header: "Data Element",
		key: "dataElement",
		isSortable: true,
	},
	{
		header: "Data Element Clean",
		key: "dataElementClean",
		isSortable: true,
	},
	{
		header: "Confidence",
		key: "dataElementConfidence",
		isSortable: true,
	},
];

export const elementsTestRowData = [
	{
		elementId: 175,
		id: "175",
		fileId: 261,
		page: 1,
		fileName: "Employer Letter 2",
		dataElement: "N18 1AA",
		dataElementType: "Postcode",
		dataElementClean: "N18 1AA",
		dataElementConfidence: "HIGH",
		ocrConfidence: 99,
		dataElementIndex: 1,
		dataElementTypeIdentifier: 4,
	},
	{
		elementId: 176,
		id: "176",
		fileId: 261,
		page: 1,
		fileName: "Employer Letter 2",
		dataElement: "N9 7AY",
		dataElementType: "Postcode",
		dataElementClean: "N9 7AY",
		dataElementConfidence: "HIGH",
		ocrConfidence: 100,
		dataElementIndex: 2,
		dataElementTypeIdentifier: 4,
	},
];

export const elementsTestRowDataBeforeSelector = [
	{
		element_type: "Postcode",
		page: 1,
		matched_text: "N18 1AA",
		full_text: "15a Empire Parade, Great Cambridge Road, London N18 1AA",
		clean_text: "N18 1AA",
		element_confidence: "HIGH",
		ocr_confidence: 99.39111328125,
		text_type: "PRINTED",
		start_index: 85,
		end_index: 92,
		textract_object_ids: ["f66828ba-6608-4bb1-b2dc-0518df6cbdc1"],
		element_id: 175,
		file_id: 261,
		file_name: "Employer Letter 2.pdf",
	},
	{
		element_type: "Postcode",
		page: 1,
		matched_text: "N9 7AY",
		full_text: "N9 7AY",
		clean_text: "N9 7AY",
		element_confidence: "HIGH",
		ocr_confidence: 99.59656524658203,
		text_type: "PRINTED",
		start_index: 315,
		end_index: 321,
		textract_object_ids: ["7b766787-b4e5-4747-8051-eceb90cb85df"],
		element_id: 176,
		file_id: 261,
		file_name: "Employer Letter 2.pdf",
	},
];

export const elementsTestRowDataBeforeSelectorTwo = [
	{
		element_type: "Postcode",
		page: 1,
		matched_text: "N18 1AA",
		full_text: "15a Empire Parade, Great Cambridge Road, London N18 1AA",
		clean_text: "N18 1AA",
		element_confidence: "HIGH",
		ocr_confidence: 99.39111328125,
		text_type: "PRINTED",
		start_index: 85,
		end_index: 92,
		textract_object_ids: ["f66828ba-6608-4bb1-b2dc-0518df6cbdc1"],
		element_id: 177,
		file_id: 261,
		file_name: "Employer Letter 2.pdf",
	},
	{
		element_type: "Postcode",
		page: 1,
		matched_text: "N9 7AY",
		full_text: "N9 7AY",
		clean_text: "N9 7AY",
		element_confidence: "HIGH",
		ocr_confidence: 99.59656524658203,
		text_type: "PRINTED",
		start_index: 315,
		end_index: 321,
		textract_object_ids: ["7b766787-b4e5-4747-8051-eceb90cb85df"],
		element_id: 178,
		file_id: 261,
		file_name: "Employer Letter 2.pdf",
	},
];
