import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getElements } from "../../redux/thunks/thunks.actions";
import TableData from "../data-table/data-table.component";
import { elementsInfo } from "../../redux/elements/elements.selectors";
import { headerData, tableTitle } from "./element-list-table-info";

const ElementList = () => {
    const dispatch = useDispatch();
    const elements = useSelector(elementsInfo);

    const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [elementsToDisplay, setElementsToDisplay] = useState([]);
    const [selectedElementTypes, setSelectedElementTypes] = useState([1, 2, 3, 4, 5]);
    const [filterValue, setFilterValue] = useState("");
    const pageSizesSelection = [10, 20, 30];

    const determineElementsToDisplay = useCallback(() => {
        const filterValueLC = filterValue.toLowerCase();

        let elementsToDisplay = elements.filter(
            (el) =>
                selectedElementTypes.includes(el.dataElementTypeIdentifier) &&
                (el.fileName.toLowerCase().includes(filterValueLC) ||
                    el.dataElement.toLowerCase().includes(filterValueLC) ||
                    el.dataElementClean.toLowerCase().includes(filterValueLC) ||
                    el.dataElementConfidence.toLowerCase().includes(filterValueLC) ||
                    el.page.toString().includes(filterValueLC) ||
                    el.dataElementType.toLowerCase().includes(filterValueLC))
        );

        setTotalNumberOfItems(elementsToDisplay.length);

        let elementsToDisplayPage = elementsToDisplay.slice(
            pageSize * (currentPage - 1),
            pageSize * currentPage
        );

        setElementsToDisplay(elementsToDisplayPage);
    }, [elements, pageSize, currentPage, selectedElementTypes, filterValue]);

    useEffect(() => {
        determineElementsToDisplay();
    }, [determineElementsToDisplay]);

    useEffect(() => {
        dispatch(getElements());
    }, [dispatch]);

    return (
        <div
            className="list results-margin"
            id="element-list"
            data-testid="element-list"
        >
            <TableData
                headerData={headerData}
                rowData={elementsToDisplay}
                tableTitle={tableTitle}
                totalNumberOfItems={totalNumberOfItems}
                currentPage={currentPage}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                setFilterValue={setFilterValue}
                pageLocation="Results"
                setSelectedElementTypes={setSelectedElementTypes}
                setPageSize={setPageSize}
                pageSizesSelection={pageSizesSelection}
            />
        </div>
    );
};

export default ElementList;
