import React from "react";
import {
    TableToolbar,
    TableBatchAction,
    TableBatchActions,
    TableToolbarContent,
    TableToolbarSearch,
} from "carbon-components-react";
import { TrashCan16, Download16 } from "@carbon/icons-react";
import TableDataToolbarBatchActions from "./table-toolbar-batch-actions.component";

const TableDataTableToolbar = ({
    getToolbarProps,
    pageLocation,
    batchActionProps,
    deleteActionClick,
    setSelectedElementTypes,
    downloadActionClick,
    selectedRows,
    setFilterValue,
}) => {
    return (
        <>
            <TableToolbar {...getToolbarProps()}>
                {pageLocation === "Documents" ? (
                    <TableBatchActions {...batchActionProps}>
                        <TableBatchAction
                            data-testid="data-table-batch-action-delete"
                            tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                            renderIcon={TrashCan16}
                            onClick={(e) => deleteActionClick(e, selectedRows)}
                        >
                            Delete
                        </TableBatchAction>
                        <TableBatchAction
                            data-testid="data-table-batch-action-download"
                            tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                            renderIcon={Download16}
                            onClick={(e) => downloadActionClick(e, selectedRows)}
                        >
                            Download Report
                        </TableBatchAction>
                    </TableBatchActions>
                ) : null}

                <TableToolbarContent
                    aria-hidden={batchActionProps.shouldShowBatchActions}
                >
                    <TableToolbarSearch
                        persistent={true}
                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                        onChange={(e) => {
                            setFilterValue(e.target.value);
                        }}
                        data-testid="data-table-toolbar-search"
                    />
                    {pageLocation === "Documents" ? null : (
                        <TableDataToolbarBatchActions
                            batchActionProps={batchActionProps}
                            setSelectedElementTypes={setSelectedElementTypes}
                            downloadActionClick={downloadActionClick}
                        />
                    )}
                </TableToolbarContent>
            </TableToolbar>
        </>
    );
};

export default TableDataTableToolbar;
