import types from './auth.types';

export const initialState = {
    authToken: null,
    tokenType: null,
    loginError: null,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
		case types.SET_AUTH_TOKEN:
			return { ...state, authToken: action.payload };
		case types.SET_TOKEN_TYPE:
			return { ...state, tokenType: action.payload };
		case types.SET_LOGIN_ERROR:
			return { ...state, loginError: action.payload };
		case types.CLEAR_LOGIN_ERROR:
			return { ...state, loginError: null };
		default:
			return state;
	}
}

export default authReducer;