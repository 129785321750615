import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getLoginTokenAPI = async (user, pass) => {
  let formData = new FormData();
  formData.append("username", user);
  formData.append("password", pass);
  try {
    const response = await axios.post(BASE_URL + "/token", formData);
    return response;
  } catch (e) {
    throw e;
  }
};

export const getUserFilesAPI = async (headers) => {
  try {
    const response = await axios.get(BASE_URL + "/files/?limit=200", {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

export const uploadDocumentsAPI = async (file, headers) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(BASE_URL + "/files/", formData, {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteDocumentAPI = async (fileId, headers) => {
  try {
    const response = await axios.delete(BASE_URL + "/files/" + fileId, {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

export const downloadDocumentElementsAPI = async (fileIds, headers) => {
  let data;
  if (fileIds) {
    data = {
      file_ids: fileIds,
    };
  } else {
    data = null;
  }

  try {
    const response = await axios.post(BASE_URL + "/elements/report", data, {
      headers: headers,
      responseType: "blob", // important
    });

    return response;
  } catch (e) {
    throw e;
  }
};

export const getElementsAPI = async (headers) => {
  try {
    const response = await axios.get(BASE_URL + "/elements/?limit=200", {
      headers: headers,
    });

    return response;
  } catch (e) {
    throw e;
  }
};

export const callSearchAPI = async (searchTerm, headers) => {
  try {
    const response = await axios.get(
      BASE_URL + "/files/search?search_term=" + searchTerm,
      {
        headers: headers,
      }
    );

    return response;
  } catch (e) {
    throw e;
  }
};
