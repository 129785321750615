import { Column, Row } from "carbon-components-react";
import { useSelector } from "react-redux";
import HeaderComponent from "../../components/header/header.component";
import SearchBar from "../../components/search-bar/search-bar.component";
import SearchResult from "../../components/search-result/search-result.component";
import { searchResultsInfo } from "../../redux/search/search.selectors";

require("dotenv").config();

const SearchPage = () => {
  const searchResults = useSelector(searchResultsInfo);

  var searchElements;

  const renderSearchElements = () => {
    if (searchResults.length === 0) {
      return <SearchResult fileName="" searchHeadline="No results" />;
    } else {
      searchElements = searchResults.map((result) => {
        return <SearchResult key={result.fileId} {...result} />;
      });
      return searchElements;
    }
  };

  return (
    <div className="bx--grid-full-width">
      <HeaderComponent isLogin={false} />
      <div className="bx--grid">
        <Row style={{ display: "unset" }}>
          <Column sm={16} md={16} lg={16}>
            <SearchBar/>
            <div className="search-result-list">{renderSearchElements()}</div>
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default SearchPage;
