import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../../redux/thunks/thunks.actions";
import TableData from "../data-table/data-table.component";
import { documentsInfo } from "../../redux/documents/documents.selectors";
import { headerData, tableTitle, tableDescription } from "./document-list-table-info";

const DocumentList = () => {
    const dispatch = useDispatch();
    const documents = useSelector(documentsInfo);

    const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [docsToDisplay, setDocsToDisplay] = useState([]);
    const pageSizesSelection = [10, 20, 30];
    const [filterValue, setFilterValue] = useState("");

    const setDocumentsToDisplay = useCallback(() => {
        const filterValueLC = filterValue.toLowerCase();

        let docsToDisplay = documents.filter(
            (doc) =>
                doc.fileName.toLowerCase().includes(filterValueLC) ||
                doc.fileType.toLowerCase().includes(filterValueLC) ||
                doc.createdTS.toLowerCase().includes(filterValueLC)
        );

        setTotalNumberOfItems(docsToDisplay.length);

        let docsToDisplayPage = docsToDisplay.slice(
            pageSize * (currentPage - 1),
            pageSize * currentPage
        );

        setDocsToDisplay(docsToDisplayPage);
    }, [documents, pageSize, currentPage, filterValue]);

    useEffect(() => {
        setDocumentsToDisplay();
    }, [setDocumentsToDisplay]);

    useEffect(() => {
        dispatch(getFiles());
    }, [dispatch]);

    return (
        <div className="list" id="document-list" data-testid="document-list">
            <TableData
                headerData={headerData}
                rowData={docsToDisplay}
                tableTitle={tableTitle}
                description={tableDescription}
                totalNumberOfItems={totalNumberOfItems}
                currentPage={currentPage}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                setFilterValue={setFilterValue}
                pageLocation="Documents"
                setPageSize={setPageSize}
                pageSizesSelection={pageSizesSelection}
            />
        </div>
    );
};

export default DocumentList;
