import types from "./documents.types";
import { filterDocuments, updateDocuments } from "./documents.utils";

export const initialState = {
	documents: [],
	documentsAlreadyUploaded: [],
};

export const documentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_DOCUMENTS:
			return { ...state, documents: action.payload };
		case types.ADD_DOCUMENT:
			return {
				...state,
				documents: [...state.documents, action.payload],
			};
		case types.REMOVE_DOCUMENT:
			return {
				...state,
				documents: filterDocuments(action.payload, state.documents),
			};
		case types.UPDATE_DOCUMENT:
			return {
				...state,
				documents: updateDocuments(action.payload, state.documents),
			};
		case types.SET_ALREADY_UPLOADED:
			return {
				...state,
				documentsAlreadyUploaded: [
					...state.documentsAlreadyUploaded,
					action.payload,
				],
			};
		case types.CLEAR_ALREADY_UPLOADED:
			return {
				...state,
				documentsAlreadyUploaded: [],
			};
		default:
			return state;
	}
};

export default documentsReducer;
