export const filterDocuments = (payloadFileId, documents) => {
    let returnFilteredDocuments = documents.filter(
        (doc) => parseInt(doc.file_id) !== parseInt(payloadFileId)
    );
    return returnFilteredDocuments;
};

export const updateDocuments = (websocketMessage, documents) => {
    let websocketFileId = websocketMessage["fileId"];
    let websocketFileStatus = websocketMessage["message"];
    let websocketOCRConfidence = websocketMessage["websocketOCRConfidence"];
    // in order to update the object I had to make a deep copy of it and then update it
    // otherwise the documents state is being updated directly and an error: state is
    // being updated during dispatch is raised
    let documentsCopy = JSON.parse(JSON.stringify(documents));

    for (let doc of documentsCopy) {
        if (doc.file_id === websocketFileId) {
            doc.upload_status = websocketFileStatus;
            doc.confidence = websocketOCRConfidence;
        }
    }

    return documentsCopy;
};
