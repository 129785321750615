import { createSelector } from "reselect";

const elements = (state) => state.elements.elements;

const determineElementIdentifier = (dataElementType) => {
    let elementIdentifier;

    if (dataElementType === "National Insurance Number") {
        elementIdentifier = 1;
    } else if (dataElementType === "NHS Number") {
        elementIdentifier = 2;
    } else if (dataElementType === "Telephone Number") {
        elementIdentifier = 3;
    } else if (dataElementType === "Postcode") {
        elementIdentifier = 4;
    } else if (dataElementType === "UC Contract Number") {
        elementIdentifier = 5;
    }

    return elementIdentifier;
};

export const elementsInfo = createSelector([elements], (element) => {
    return element.map((item, i) => ({
        elementId: item["element_id"],
        id: String(item["element_id"]),
        fileId: item["file_id"],
        page: item["page"],
        fileName: item["file_name"],
        dataElement: item["matched_text"],
        dataElementType: item["element_type"],
        dataElementClean: item["clean_text"],
        dataElementConfidence: item["element_confidence"],
        ocrConfidence: Math.round(item["ocr_confidence"]),
        dataElementIndex: i + 1,
        dataElementTypeIdentifier: determineElementIdentifier(item["element_type"]),
    }));
});
