import { CheckmarkFilled16, ErrorFilled16, WarningFilled16 } from "@carbon/icons-react";

export const headerData = [
    {
        header: "Document Name",
        key: "fileName",
        isSortable: true,
    },
    {
        header: "Type",
        key: "fileType",
        isSortable: true,
    },
    {
        header: "Created Date",
        key: "createdTS",
        isSortable: true,
    },
    {
        header: "Status",
        key: "uploadStatus",
        isSortable: true,
    },
    {
        header: " ",
        key: "icon",
        isSortable: false,
    },
];

export const tableTitle = "Documents";
export const tableDescription = "Documents Table Description";

export const documentsTestRowData = [
    {
        fileId: 1,
        id: "1",
        uploadStatus: "Uploading",
        fileName: "Test File Uploading",
        fileType: "PDF",
        createdTS: "Fri Dec 24 2021",
        fileIndex: 1,
        icon: {
            icon: null,
            status: "uploading",
            text: "Processing",
        },
    },
    {
        fileId: 2,
        id: "2",
        uploadStatus: "Succeeded",
        fileName: "Test File Succeeded",
        fileType: "PDF",
        createdTS: "Fri Dec 24 2021",
        fileIndex: 2,
        icon: {
            icon: CheckmarkFilled16,
            status: "success",
            text: "Upload Succeeded",
        },
    },
    {
        fileId: 3,
        id: "3",
        uploadStatus: "Failed",
        fileName: "Test File Failed",
        fileType: "PDF",
        createdTS: "Fri Dec 24 2021",
        fileIndex: 3,
        icon: {
            icon: ErrorFilled16,
            status: "error",
            text: "Upload Failed",
        },
    },
    {
        fileId: 7,
        id: "7",
        uploadStatus: "Succeeded",
        fileName: "Test File Succeeded",
        fileType: "PDF",
        createdTS: "Fri Dec 24 2021",
        fileIndex: 4,
        icon: {
            icon: WarningFilled16,
            status: "warning",
            text: "Low OCR Confidence",
        },
    },
];

export const documentsTestRowDataBeforeSelector = [
    {
        file_id: 1,
        upload_status: "Uploading",
        file_name: "Test File Uploading.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
    },
    {
        file_id: 2,
        upload_status: "Succeeded",
        file_name: "Test File Succeeded.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
    },
    {
        file_id: 3,
        upload_status: "Failed",
        file_name: "Test File Failed.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
    },
    {
        file_id: 7,
        upload_status: "Succeeded",
        file_name: "Test File Succeeded.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
        confidence: 60,
    },
];

export const documentsTestRowDataBeforeSelectorTwo = [
    {
        file_id: 4,
        upload_status: "Uploading",
        file_name: "Test File Uploading.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
    },
    {
        file_id: 5,
        upload_status: "Succeeded",
        file_name: "Test File Succeeded.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
    },
    {
        file_id: 6,
        upload_status: "Failed",
        file_name: "Test File Failed.pdf",
        file_type: "PDF",
        created_ts: "Fri Dec 24 2021",
    },
];
