import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
	Modal,
	FileUploaderDropContainer,
	FileUploaderItem,
} from "carbon-components-react";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocuments } from "../../redux/thunks/thunks.actions";

const AddFileModal = ({ showFileModal, onSubmitFiles, onToggleFileModal }) => {
	const dispatch = useDispatch();

	const [files, setFiles] = useState([]);
	const [repeatedDocuments, setRepeatedDocuments] = useState([]);
	const allUploadedDocuments = useSelector((state) => state.documents.documents);

	const addFile = (event, addedFiles) => {
		if (addedFiles) {
			setFiles([...files, ...addedFiles.addedFiles]);
		}
	};

	const removeFile = (fileName) => {
		setFiles(files.filter((file) => file.name !== fileName));
	};

	const submitFiles = (e) => {
		e.preventDefault();
		const uploadedFilesNotNull = files.length !== 0;

		if (uploadedFilesNotNull) {
			setDocumentAlreadyExistsErrorAlert(repeatedDocuments);
			dispatch(uploadDocuments(files));
			setFiles([]);
			onSubmitFiles();
		} else {
			let alertMessage = "Please choose at least one document to be uploaded.";
			alert(alertMessage);
		}
	};

	const determineDocumentAlreadyExistsError = useCallback(
		(files) => {
			let allUploadedDocumentsFileNames = new Set(
				allUploadedDocuments.map((document) => document.file_name)
			);

			let repeatedDocs = [];

			for (let file of files) {
				if (allUploadedDocumentsFileNames.has(file.name)) {
					repeatedDocs.push(file.name);
				}
			}
			setRepeatedDocuments(repeatedDocs);
		},
		[setRepeatedDocuments, allUploadedDocuments]
	);

	const setDocumentAlreadyExistsErrorAlert = (repeatedDocuments) => {
		if (repeatedDocuments.length === 1) {
			alert(`Cannot add file with the same name ${repeatedDocuments[0]}.`);
		} else if (repeatedDocuments.length > 1) {
			alert(
				`Cannot add files with the same names ${repeatedDocuments
					.slice(0, -1)
					.join(", ")} and ${repeatedDocuments.slice(-1)}.`
			);
		}
	};

	useEffect(() => {
		determineDocumentAlreadyExistsError(files);
	}, [files, determineDocumentAlreadyExistsError]);

	return (
		<Fragment>
			<Modal
				id="file-add-modal"
				className="file-add-modal"
				data-testid="file-add-modal"
				modalHeading={`Upload File`}
				open={showFileModal}
				primaryButtonText="Upload"
				secondaryButtonText="Cancel"
				hasScrollingContent={false}
				iconDescription="Close"
				size="sm"
				onRequestSubmit={submitFiles}
				onRequestClose={onToggleFileModal}
			>
				<p className="file-modal-text">
					Supported file types are .pdf, .jpeg, .png and .tiff
				</p>
				<FileUploaderDropContainer
					multiple={true}
					data-testid="file-drop-upload"
					onAddFiles={addFile}
					labelText="Drag and drop files here or click to select"
				></FileUploaderDropContainer>
				<div
					className="uploaded-file-container"
					data-testid="uploaded-file-container"
				>
					{files.map((file) => (
						<FileUploaderItem
							key={file.name}
							name={file.name}
							onDelete={(e) => removeFile(file.name)}
							status="edit"
						/>
					))}
				</div>
			</Modal>
		</Fragment>
	);
};

export default AddFileModal;
