import React from "react";
import { DocumentAdd32 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import { useDispatch } from "react-redux";
import { clearApiError } from "../../redux/api/api.actions";

const AddFileButton = ({ onToggleFileModal }) => {
	const dispatch = useDispatch();

	const toggleFileModal = () => {
		onToggleFileModal();
		dispatch(clearApiError());
	};

	return (
		<div className="add-buttons-div higher-z-index">
			<>
				<Button
					id="show-add-file-modal-button"
					data-testid="show-add-file-modal-button"
					hasIconOnly={true}
					kind="secondary"
					iconDescription="Add Documents"
					className="button-design"
					size="sm"
					onClick={toggleFileModal}
				>
					<DocumentAdd32
						className="file-icon"
						data-testid="add-file-button-icon"
					/>
				</Button>
			</>
		</div>
	);
};

export default AddFileButton;
