import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const PrivateRoute = ({ children, redirectTo }) => {
	const authToken = useSelector((state) => state.auth.authToken);

	if (authToken) {
		return authToken ? children : <Navigate to={redirectTo} />;
	} else {
		return <Navigate to="/login" />;
	}
};

PrivateRoute.propTypes = {
	component: PropTypes.elementType,
};

export default PrivateRoute;
