import React, { useEffect } from "react";

import LoginCard from "../../components/login-card/login-card.component";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginError } from "../../redux/auth/auth.actions";
import { clearSearchResults } from "../../redux/search/search.actions";

const LoginPage = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const loginError = useSelector((state) => state.auth.loginError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      if (loginError) {
        dispatch(setLoginError(null));
      }
      dispatch(clearSearchResults());
      navigate("/");
    }
  }, [authToken, dispatch, loginError, navigate]);

  return (
    <div className="login-page-background" data-testid="login-page-background">
      <LoginCard />
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
