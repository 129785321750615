import { Tile } from "carbon-components-react";
import React from "react";

const SearchResult = ({ fileName, searchHeadline }) => {
  return (
    <>
      <Tile style={{ marginTop: "16px" }} data-testid="search-result">
        {fileName.length > 0 ? <h4>{fileName}</h4> : " "}
        <section
          style={{ marginTop: "16px" }}
          dangerouslySetInnerHTML={{ __html: searchHeadline }}
        />
      </Tile>
    </>
  );
};

export default SearchResult;
