import types from "./auth.types";

export const setAuthToken = (token) => {
    return { type: types.SET_AUTH_TOKEN, payload: token };
};

export const setTokenType = (token) => {
    return { type: types.SET_TOKEN_TYPE, payload: token };
};

export const setLoginError = (message) => {
    return { type: types.SET_LOGIN_ERROR, payload: message };
};

export const clearLoginError = () => {
	return { type: types.CLEAR_LOGIN_ERROR };
};