import {
  callSearchAPI,
  deleteDocumentAPI,
  downloadDocumentElementsAPI,
  getElementsAPI,
  getLoginTokenAPI,
  getUserFilesAPI,
  uploadDocumentsAPI,
} from "../../api/index.js";
import {
  getHeaders,
  getZipFile,
  handleApiError,
} from "../../helpers/helper-functions.js";
import wsConnect from "../../redux/websocket/middleware.actions";
import {
  clearLoginError,
  setAuthToken,
  setTokenType,
} from "../auth/auth.actions";
import {
  addDocument,
  downloadDocument,
  removeDocument,
  setDocuments,
} from "../documents/documents.actions.js";
import { setElements } from "../elements/elements.actions.js";
import { setSearchResults } from "../search/search.actions.js";

export const getLoginToken = (user, pass) => {
  return (dispatch) => {
    let isLoginEndpoint = true;
    dispatch(clearLoginError());

    getLoginTokenAPI(user, pass)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(setAuthToken(res.data.access_token));
          dispatch(setTokenType(res.data.token_type));
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch, isLoginEndpoint);
      });
  };
};

export const getFiles = () => {
  return (dispatch, getState) => {
    const state = getState();
    const headers = getHeaders(state);
    dispatch(clearLoginError());

    let isLoginEndpoint = false;

    getUserFilesAPI(headers)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(setDocuments(res.data.files));
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch, isLoginEndpoint);
      });
  };
};

export const uploadDocuments = (filesToUpload) => {
  return (dispatch, getState) => {
    const state = getState();
    const headers = getHeaders(state);
    dispatch(clearLoginError());

    let isLoginEndpoint = false;

    for (let file of filesToUpload) {
      uploadDocumentsAPI(file, headers)
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            dispatch(addDocument(res.data));
          }
        })
        .catch((error) => {
          handleApiError(error, dispatch, isLoginEndpoint);
        });
    }
  };
};

export const deleteDocuments = (fileId) => {
  return (dispatch, getState) => {
    const state = getState();
    const headers = getHeaders(state);
    dispatch(clearLoginError());

    let isLoginEndpoint = false;

    deleteDocumentAPI(fileId, headers)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(removeDocument(fileId));
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch, isLoginEndpoint);
      });
  };
};

export const downloadDocumentElements = (fileIds) => {
  return (dispatch, getState) => {
    const state = getState();
    const headers = getHeaders(state);
    dispatch(clearLoginError());

    let isLoginEndpoint = false;

    downloadDocumentElementsAPI(fileIds, headers)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          let zipFileName = "OCR_Tool_Report_" + String(Date.now()) + ".xlsx";
          getZipFile(res.data, zipFileName);
          dispatch(downloadDocument(fileIds));
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch, isLoginEndpoint);
      });
  };
};

export const establishWebsocketConnection = () => {
  const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

  return (dispatch) => {
    dispatch(wsConnect(WEBSOCKET_URL));
  };
};

export const getElements = () => {
  return (dispatch, getState) => {
    const state = getState();
    const headers = getHeaders(state);
    dispatch(clearLoginError());

    let isLoginEndpoint = false;

    getElementsAPI(headers)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(setElements(res.data.elements));
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch, isLoginEndpoint);
      });
  };
};

export const callSearch = (searchTerm) => {
  return (dispatch, getState) => {
    const state = getState();
    const headers = getHeaders(state);
    dispatch(clearLoginError());

    let isLoginEndpoint = false;

    callSearchAPI(searchTerm, headers)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(setSearchResults(res.data));
        }
      })
      .catch((error) => {
        handleApiError(error, dispatch, isLoginEndpoint);
      });
  };
};
