const pre = "documents/";
const SET_DOCUMENTS = pre + "SET_DOCUMENTS";
const ADD_DOCUMENT = pre + "ADD_DOCUMENT";
const REMOVE_DOCUMENT = pre + "REMOVE_DOCUMENT";
const UPDATE_DOCUMENT = pre + "UPDATE_DOCUMENT";
const DOWNLOAD_DOCUMENT = pre + "DOWNLOAD_DOCUMENT";
const SET_ALREADY_UPLOADED = pre + "SET_ALREADY_UPLOADED";
const CLEAR_ALREADY_UPLOADED = pre + "CLEAR_ALREADY_UPLOADED";

const documents = {
	SET_DOCUMENTS,
	ADD_DOCUMENT,
	REMOVE_DOCUMENT,
	UPDATE_DOCUMENT,
	DOWNLOAD_DOCUMENT,
	SET_ALREADY_UPLOADED,
	CLEAR_ALREADY_UPLOADED,
};

export default documents;
