import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableContainer,
    TableSelectAll,
    Pagination,
} from "carbon-components-react";
import React, { Fragment } from "react";
import {
    deleteDocuments,
    downloadDocumentElements,
} from "../../redux/thunks/thunks.actions";
import { useDispatch } from "react-redux";
import TableDataTableBody from "./table-table-body.component";
import TableDataTableToolbar from "./data-table-table-toolbar.component";

const TableData = ({
    headerData,
    rowData,
    tableTitle,
    description,
    totalNumberOfItems,
    setCurrentPage,
    setFilterValue,
    currentPage,
    pageSize,
    pageLocation,
    setSelectedElementTypes,
    setPageSize,
    pageSizesSelection,
}) => {
    const dispatch = useDispatch();
    const deleteActionClick = (e, selectedRows) => {
        if (pageLocation === "Documents") {
            for (let doc of selectedRows) {
                let documentId = doc.id;
                dispatch(deleteDocuments(documentId));
            }
        } else {
        }
    };

    const downloadActionClick = (e, selectedRows) => {
        e.preventDefault();
        if (pageLocation === "Documents") {
            let fileIds = selectedRows.map((doc) => doc.id);
            dispatch(downloadDocumentElements(fileIds));
        } else {
            dispatch(downloadDocumentElements(null));
        }
    };

    return (
        <Fragment>
            <DataTable
                rows={rowData}
                headers={headerData}
                data-testid="data-table-component"
                zebra={true}
                stickyHeader={true}
            >
                {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getSelectionProps,
                    getToolbarProps,
                    getBatchActionProps,
                    selectedRows,
                    getTableProps,
                    getTableContainerProps,
                }) => {
                    const batchActionProps = getBatchActionProps();
                    return (
                        <TableContainer
                            title={tableTitle}
                            {...getTableContainerProps()}
                            data-testid="data-table-container"
                        >
                            <TableDataTableToolbar
                                getToolbarProps={getToolbarProps}
                                pageLocation={pageLocation}
                                batchActionProps={batchActionProps}
                                deleteActionClick={deleteActionClick}
                                setSelectedElementTypes={setSelectedElementTypes}
                                downloadActionClick={downloadActionClick}
                                selectedRows={selectedRows}
                                setFilterValue={setFilterValue}
                            />
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {pageLocation === "Documents" ? (
                                            <TableSelectAll {...getSelectionProps()} />
                                        ) : null}
                                        {headers.map((header, i) => (
                                            <TableHeader
                                                data-testid="data-table-header"
                                                key={i}
                                                {...getHeaderProps({
                                                    header,
                                                    isSortable: header.isSortable,
                                                    isSortHeader: true,
                                                })}
                                            >
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableDataTableBody
                                    getRowProps={getRowProps}
                                    getSelectionProps={getSelectionProps}
                                    rows={rows}
                                    pageLocation={pageLocation}
                                />
                            </Table>
                            <Pagination
                                backwardText="Previous page"
                                forwardText="Next page"
                                itemsPerPageText="Items per page:"
                                pageNumberText="Page Number"
                                id="pagination-component"
                                data-testid="pagination-component"
                                className="pagination-location pagination-size"
                                page={currentPage}
                                pageSize={pageSize}
                                pageSizes={pageSizesSelection}
                                totalItems={totalNumberOfItems}
                                onChange={(event) => {
                                    setCurrentPage(event.page);
                                    setPageSize(event.pageSize);
                                }}
                            />
                        </TableContainer>
                    );
                }}
            </DataTable>
        </Fragment>
    );
};

export default TableData;
