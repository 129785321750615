import { Checkbox, ToastNotification } from "carbon-components-react";
import { FilterEdit16, Download16, FilterReset16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState, useCallback } from "react";

const TableDataToolbarBatchActions = ({
    batchActionProps,
    setSelectedElementTypes,
    downloadActionClick,
}) => {
    const [isNIChecked, setIsNIChecked] = useState(true);
    const [isNHSChecked, setIsNHSChecked] = useState(true);
    const [isPhoneNumberChecked, setIsPhoneNumberChecked] = useState(true);
    const [isPostcodeChecked, setIsPostcodeChecked] = useState(true);
    const [isUCContractChecked, setIsUCContractChecked] = useState(true);
    const [showNotification, setShowNotification] = useState(false);

    const checkCheckedElementTypes = useCallback(() => {
        let elementsSelected = [];

        if (isNIChecked) {
            elementsSelected.push(1);
        }
        if (isNHSChecked) {
            elementsSelected.push(2);
        }
        if (isPhoneNumberChecked) {
            elementsSelected.push(3);
        }
        if (isPostcodeChecked) {
            elementsSelected.push(4);
        }
        if (isUCContractChecked) {
            elementsSelected.push(5);
        }
        setSelectedElementTypes(elementsSelected);
    }, [
        isNIChecked,
        isNHSChecked,
        isPhoneNumberChecked,
        isPostcodeChecked,
        isUCContractChecked,
        setSelectedElementTypes,
    ]);

    const resetFilter = () => {
        setIsNIChecked(true);
        setIsNHSChecked(true);
        setIsPhoneNumberChecked(true);
        setIsPostcodeChecked(true);
        setIsUCContractChecked(true);
        setSelectedElementTypes([1, 2, 3, 4, 5]);
    };

    useEffect(() => {
        checkCheckedElementTypes();
    }, [checkCheckedElementTypes]);

    return (
        <>
            <Button
                renderIcon={FilterEdit16}
                id="results-page-filter-button"
                data-testid="results-page-filter-button"
                className="results-download-icon"
                iconDescription="Filter Data"
                hasIconOnly
                onClick={() => setShowNotification(!showNotification)}
            />
            {showNotification ? (
                <div
                    className="notification-checkbox-div"
                    data-testid="data-table-toolbar-menu"
                >
                    <ToastNotification
                        icon={null}
                        kind="info"
                        lowContrast
                        onCloseButtonClick={() =>
                            setShowNotification(!showNotification)
                        }
                        title=""
                        subtitle={null}
                        caption={null}
                    >
                        <Checkbox
                            className="results-filter-checkbox"
                            labelText={`National Insurance Number`}
                            id="national-insurance-number-checkbox"
                            data-testid="national-insurance-number-checkbox"
                            checked={isNIChecked}
                            onChange={() => setIsNIChecked(!isNIChecked)}
                        />
                        <Checkbox
                            className="results-filter-checkbox"
                            labelText={`NHS Number`}
                            id="nhs-number-checkbox"
                            data-testid="nhs-number-checkbox"
                            checked={isNHSChecked}
                            onChange={() => setIsNHSChecked(!isNHSChecked)}
                        />
                        <Checkbox
                            className="results-filter-checkbox"
                            labelText={`Telephone Number`}
                            id="telephone-number-checkbox"
                            data-testid="telephone-number-checkbox"
                            checked={isPhoneNumberChecked}
                            onClick={() =>
                                setIsPhoneNumberChecked(!isPhoneNumberChecked)
                            }
                        />
                        <Checkbox
                            className="results-filter-checkbox"
                            labelText={`Postcode`}
                            id="postcode-checkbox"
                            data-testid="postcode-checkbox"
                            checked={isPostcodeChecked}
                            onClick={() => setIsPostcodeChecked(!isPostcodeChecked)}
                        />
                        <Checkbox
                            className="results-filter-checkbox"
                            labelText={`UC Contract Number`}
                            id="uc-contract-number-checkbox"
                            data-testid="uc-contract-number-checkbox"
                            checked={isUCContractChecked}
                            onClick={() => setIsUCContractChecked(!isUCContractChecked)}
                        />
                    </ToastNotification>
                </div>
            ) : null}
            <Button
                renderIcon={FilterReset16}
                id="results-page-reset-filter-button"
                data-testid="results-page-reset-filter-button"
                className="results-download-icon"
                iconDescription="Reset Filter"
                hasIconOnly
                onClick={resetFilter}
            />
            <Button
                renderIcon={Download16}
                id="results-page-download-button"
                data-testid="results-page-download-button"
                className="results-download-icon"
                iconDescription="Download Data"
                hasIconOnly
                onClick={(e) => downloadActionClick(e, [])}
            />
        </>
    );
};

export default TableDataToolbarBatchActions;
