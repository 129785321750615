import React, { useEffect, useState } from "react";
// import { withRouter, Link } from "react-router-dom";
import ArrowRight16 from "@carbon/icons-react/lib/arrow--right/16";
import {
    Button,
    Form,
    InlineNotification,
    TextInput,
} from "carbon-components-react";
import { useDispatch, useSelector } from "react-redux";
import login from "../../assets/login.png";
import { clearLoginError } from "../../redux/auth/auth.actions";
import { getLoginToken } from "../../redux/thunks/thunks.actions";

const LoginCard = () => {
    const [user, setUser] = useState(null);
    const [pass, setPass] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [type, setType] = useState("password");

    const loginError = useSelector((state) => state.auth.loginError);
    const dispatch = useDispatch();

    const [width, setWidth] = useState(window.innerWidth);

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(clearLoginError());

        if (!user || !pass) {
            setErrMsg("*You must enter a username and password");
        } else {
            dispatch(getLoginToken(user, pass));
        }
    };

    const togglePasswordVisibility = () => {
        setType(type === "password" ? "text" : "password");
    };

    const reactAppVersion = process.env.REACT_APP_VERSION;

    useEffect(() => {
        if (loginError) {
            setErrMsg(loginError);
        }
    }, [loginError]);

    useEffect(() => {}, [reactAppVersion]);
    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);


    return (
        <div className="card-container" data-testid="login-card-container">
            <div className="fields-area">
                <Form>
                    <div className="login-title">Log in</div>
                    <div className="input">
                        <TextInput
                            id="email"
                            data-testid="login-email-input"
                            labelText="Email"
                            onChange={(e) => setUser(e.target.value)}
                            type="text"
                            placeholder="username@email.com"
                        />
                    </div>
                    <div className="input">
                        <TextInput.ControlledPasswordInput
                            id="password"
                            data-testid="login-password-input"
                            labelText="Password"
                            togglePasswordVisibility={togglePasswordVisibility}
                            onChange={(e) => setPass(e.target.value)}
                            type={type}
                            placeholder="Password"
                            // helperText={<Link to="#">Forgot password</Link>}
                        />
                    </div>
                    {errMsg ? (
                        <div className="error-div" data-testid="error-div">
                            <InlineNotification
                                kind="error"
                                id="login-notification"
                                data-testid="login-notification"
                                title={errMsg}
                                lowContrast={true}
                                className="inline-notification fade-in-1"
                                hideCloseButton={true}
                            />
                        </div>
                    ) : null}
                    <Button
                        type="submit"
                        data-testid="Log in"
                        onClick={handleLogin}
                    >
                        Log in
                        <ArrowRight16
                            className="login-button-arrow"
                            data-testid="login-login-button-arrow"
                        />
                    </Button>
                </Form>

                <div
                    className="login-app-version"
                    data-testid="login-app-version"
                >
                    version {reactAppVersion}
                </div>
            </div>

            {width < 1024 ? null : (
                <img
                    className="login-image"
                    data-testid="login-image"
                    src={login}
                    alt="login"
                />
            )}
        </div>
    );
};

LoginCard.propTypes = {};

export default LoginCard;
