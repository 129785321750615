import { createSelector } from "reselect";
import { CheckmarkFilled16, ErrorFilled16, WarningFilled16 } from "@carbon/icons-react";
const documents = (state) => state.documents.documents;
const alreadyExists = (state) => state.documents.documentsAlreadyUploaded;

const setCreatedTS = (timestamp) => {
    let date = new Date(timestamp);
    date = date.toDateString();
    return date;
};

const removeFileExtension = (fileName) => {
    const newFileName = fileName.split(".").slice(0, -1).join(".");
    return newFileName;
};

const capitalizeFirstLetter = (string) => {
    const newFileName = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return newFileName;
};

const determineIconInfo = (uploadStatus, ocrConfidence) => {
    uploadStatus = uploadStatus.toLowerCase();

    let iconInfoObject = {};

    if (uploadStatus === "failed") {
        iconInfoObject.icon = ErrorFilled16;
        iconInfoObject.status = "error";
        iconInfoObject.text = "Upload Failed";
    } else if (uploadStatus === "succeeded") {
        if (ocrConfidence < 75) {
            iconInfoObject.icon = WarningFilled16;
            iconInfoObject.status = "warning";
            iconInfoObject.text = "Low OCR Confidence";
        } else {
            iconInfoObject.icon = CheckmarkFilled16;
            iconInfoObject.status = "success";
            iconInfoObject.text = "Upload Succeeded";
        }
    } else {
        iconInfoObject.icon = null;
        iconInfoObject.status = "uploading";
        iconInfoObject.text = "Processing";
    }
    return iconInfoObject;
};

export const documentsInfo = createSelector([documents], (document) => {
    return document.map((item, i) => ({
        fileId: item["file_id"],
        id: String(item["file_id"]),
        uploadStatus: capitalizeFirstLetter(item["upload_status"]),
        fileName: removeFileExtension(item["file_name"]),
        fileType: item["file_type"],
        createdTS: setCreatedTS(item["created_ts"]),
        fileIndex: i + 1,
        icon: determineIconInfo(item["upload_status"], item["confidence"]),
    }));
});

export const docsAlreadyExist = createSelector([alreadyExists], (document) => {
    let uniqueDocs = Array.from(new Set(document));
    let error = null;

    if (uniqueDocs.length === 1) {
        error = `Cannot add file with the same name ${uniqueDocs[0]}`;
    } else {
        error = `Cannot add files with the same names ${uniqueDocs
            .slice(0, -1)
            .join(", ")} and ${uniqueDocs.slice(-1)}.`;
    }
    return error;
});
